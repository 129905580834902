import { computed, ref, watch } from "@vue/composition-api";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import appUtils from "@/utils/appUtils";
import i18n from "@/libs/i18n";
import store from "@/store";
import { useToast } from "vue-toastification/composition";

export default function useStatusList() {
  // Use toast
  const toast = useToast();
  const showTable = ref(false);
  const refDataTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "id", label: "#ID", sortable: false, thClass: "text-nowrap" },
    {
      key: "title",
      label: i18n.t("lbl_title_name_status_config"),
      sortable: false,
      thClass: "text-nowrap",
    },
    { key: "action", label: "", thClass: "text-right text-nowrap" },
  ];

  const perPage = ref(10);
  const totals = ref(0);
  const currentPage = ref(1);
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const recordDetail = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refDataTable.value
      ? refDataTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totals.value,
    };
  });

  const refetchData = () => {
    refDataTable.value.refresh();
  };

  const fetchDeliveryStatusConfigsListWithPaging = (ctx, callback) => {
    const userData = appUtils.getLocalUser();

    if (!userData || !userData?.supplierInfo?.id) return;

    store
      .dispatch("categories/fetchDeliveryStatusConfigsListWithPaging", {
        keyword: searchQuery.value,
        page_size: perPage.value,
        page_num: currentPage.value,
        sortBy: sortBy.value,
        order: isSortDirDesc.value ? "desc" : "asc",
        supplier_id: userData?.supplierInfo?.id,
      })
      .then((response) => {
        const data = response?.data;
        totals.value = response?.page?.total;
        callback(data);
      })
      .catch((e) => {
        console.log(e);
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const fetchDeliveryStatusConfigsById = async (data) => {
    const params = {};

    return await store
      .dispatch("categories/fetchDeliveryStatusConfigsById", {
        id: data?.id,
        params,
      })
      .then((res) => {
        recordDetail.value = res;
        return res;
      });
  };

  const timeout = ref(null);

  watch([currentPage, perPage, searchQuery], () => {
    showTable.value = true;
    clearTimeout(timeout);
    timeout.value = setTimeout(() => {
      refetchData();
      showTable.value = false;
    }, 500);
  });

  return {
    timeout,
    showTable,
    fetchDeliveryStatusConfigsListWithPaging,
    tableColumns,
    perPage,
    currentPage,
    totals,
    dataMeta,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDataTable,
    refetchData,
    recordDetail,
    fetchDeliveryStatusConfigsById,
  };
}
