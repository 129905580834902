<template>
  <LayoutDataTable
    :isLoading="showTable"
    :searchQuery="searchQuery"
    :perPage="perPage"
    :meta="dataMeta"
    :currentPage="currentPage"
    :totals="totals"
    :searchPlaceholder="$t('lbl_find_by_name_config')"
    @setPerPage="(value) => (perPage = value)"
    @setCurrentPage="(value) => (currentPage = value)"
    @setSearchQuery="(value) => (searchQuery = value)"
  >
    <template #cta>
      <b-button v-b-modal.modal-detail-delivery-status variant="primary" class="ml-1">
        <span class="text-nowrap">{{ $t('lbl_add_new') }}</span>
      </b-button>
    </template>
    <template #default>
      <b-table
        ref="refDataTable"
        class="position-relative"
        :items="fetchDeliveryStatusConfigsListWithPaging"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        hover
        :empty-text="$t('lbl_no_matching_records_found')"
        :sort-desc.sync="isSortDirDesc"
        @row-clicked="handleViewRecord"
      >
        <template #cell(action)="scope">
          <div class="text-right text-nowrap" @click.stop>
            <b-button
              v-b-tooltip.hover.top
              variant="outline-primary ml-1"
              size="sm"
              :title="$t('lbl_view_detail')"
              @click="handleViewRecord(scope.item)"
            >
              <feather-icon size="16" icon="EyeIcon" />
            </b-button>
            <b-button
              v-b-tooltip.hover.top
              variant="outline-danger ml-1"
              size="sm"
              :title="$t('lbl_delete_record', {0: scope.item.id})"
              @click="deleteRecord(scope.item)"
              record
            >
              <feather-icon size="16" icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table>

      <delivery-status-detail :record-detail="recordDetail" @refresh="refreshTable" />
    </template>
  </LayoutDataTable>
</template>

<script>
import useDeliveryStatusList from "./useDeliveryStatusList";
import LayoutDataTable from "@/layouts/table/LayoutDataTable.vue";
import appUtils from "@/utils/appUtils";
import Ripple from "vue-ripple-directive";
import DeliveryStatusDetail from "./Detail.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "StatusList",
  components: { ToastificationContent, LayoutDataTable, DeliveryStatusDetail },
  directives: {
    Ripple
  },
  data() {
    return {
      appUtils
    };
  },
  methods: {
    refreshTable() {
      this.showTable = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.refetchData();
        this.showTable = false;
      }, 500);
    },
    async handleViewRecord(record) {
      if (!record?.id) return;

      const res = await this.fetchDeliveryStatusConfigsById(record);
      if (res?.id) {
        this.$root.$emit("bv::show::modal", "modal-detail-delivery-status")
      }
    },
    deleteRecord(record) {
      if (!record?.id) {
        return;
      }

      const nodeEl = this.$createElement;
      const messageVNode = nodeEl("div", {
        domProps: {
          innerHTML: this.$t("lbl_are_you_sure_want_to_delete_record", {
            0: record?.id
          })
        }
      });

      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: this.$t("lbl_please_confirm"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("lbl_yes"),
          cancelTitle: this.$t("lbl_cancel"),
          hideHeaderClose: false,
          centered: true
        })
        .then(success => {
          if (success) {
            this.$store
              .dispatch(
                "categories/deleteDeliveryStatusConfigsById",
                record?.id
              )
              .then(res => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t("lbl_you_have_successfully_deleted"),
                    icon: "CheckIcon",
                    variant: "success"
                  }
                });
                this.refreshTable();
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t(
                      "lbl_an_error_occurred_while_deleting_the_record"
                    ),
                    icon: "AlertTriangleIcon",
                    variant: "danger"
                  }
                });
              });
          }
        })
        .catch(err => {
          // An error occurred
          console.log(err);
        });
    }
  },
  setup() {
    const mixData = useDeliveryStatusList();
    return { ...mixData };
  }
};
</script>

<style>
</style>
