<template>
  <div>
    <b-modal
      id="modal-detail-delivery-status"
      scrollable
      centered
      size="lg"
      :title="
        detail && detail.id
          ? $t('lbl_deliverable_status_config_detail')
          : $t('lbl_add_new')
      "
      :cancel-title="$t('lbl_cancel')"
      :ok-title="detail && detail.id ? $t('lbl_update') : $t('lbl_add_new')"
      cancel-variant="outline-secondary"
      @ok="handleOk"
    >
      <validation-observer ref="deliveryStatusFormValidation">
        <b-form @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="{ errors }"
                :name="$t('lbl_title_name_status_config')"
                rules="required"
              >
                <b-form-group :label="$t('lbl_title_name_status_config')" class="mb-2 required">
                  <b-form-input v-model="detail.title" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group :label="$t('lbl_title_name_status_config__english_')" class="mb-2">
                <b-form-input v-model="detail.title_en" />
                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-50">
            <b-col offset="1" md="8" class="flex items-center gap-4">
              <p class="mb-md-0 flex-1 required">{{ $t("lbl_list_vietnamese") }}</p>
              <p class="mb-md-0 flex-1">{{ $t("lbl_list_english") }}</p>
            </b-col>
            <b-col offset="3"></b-col>
          </b-row>
          <draggable
            v-model="delivery_items"
            tag="div"
            class="list-group list-group-flush"
            @change="handleDragChanged"
          >
            <b-row
              v-for="(item, index) in delivery_items"
              :id="item.id || item.code || index"
              :key="item.id || item.code || index"
              ref="row"
            >
              <b-col md="1" class="cursor-move">
                <div
                  class="d-flex h-100 w-100"
                  v-b-tooltip.hover
                  :title="$t('lbl_drag___drop_to_change_the_position')"
                >
                  <feather-icon icon="MoveIcon" class="m-auto" />
                </div>
              </b-col>
              <!-- Item Name -->
              <b-col md="8" class="flex items-center gap-4">
                <b-form-group class="mb-md-0 flex-1">
                  <validation-provider
                    #default="{ errors }"
                    :vid="`input-${item.id || item.code || index}`"
                    :name="$t('lbl_title_name_deliverable_status')"
                    rules="required"
                  >
                    <b-form-input
                      type="text"
                      v-model="item.title"
                      :id="`input-${item.id || item.code || index}`"
                      :placeholder="
                        $t('lbl_ex__receiver_order__processing__sending____')
                      "
                      :disabled="item.order_number === 100"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group class="mb-md-0 flex-1">
                  <b-form-input
                    type="text"
                    v-model="item.title_en"
                    :id="`input-${item.id || item.code || index}`"
                    :placeholder="
                      $t('lbl_ex__receiver_order__processing__sending____')
                    "
                    :disabled="item.order_number === 100"
                  />
                </b-form-group>
              </b-col>

              <!-- Buttons -->
              <b-col md="3">
                <div>
                  <b-button
                    v-b-tooltip.hover
                    v-if="delivery_items.length > 1"
                    :title="$t('lbl_remove')"
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="px-1 py-50 mr-75"
                    @click="removeItem(index, delivery_items)"
                  >
                    <feather-icon size="22" icon="Trash2Icon" />
                    <span class="sr-only">{{ $t("lbl_remove") }}</span>
                  </b-button>
                  <b-button
                    v-if="index === delivery_items.length - 1"
                    v-b-tooltip.hover
                    :title="$t('lbl_add_new')"
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-primary"
                    class="px-1 py-50"
                    @click="addNewDeliverable(delivery_items)"
                  >
                    <feather-icon size="22" icon="PlusIcon" />
                    <span class="sr-only">{{ $t("lbl_add_new") }}</span>
                  </b-button>
                </div>
              </b-col>
              <b-col
                cols="12"
                v-if="
                  index !== delivery_items.length - 1 &&
                  delivery_items.length > 1
                "
              >
                <hr />
              </b-col>
            </b-row>
          </draggable>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import appUtils from '@/utils/appUtils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import draggable from 'vuedraggable'
import { ORDER_DELIVERY_STATES } from '@/utils/constant'

export default {
  name: 'DeliveryStatusDetail',
  props: {
    recordDetail: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    draggable,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  data() {
    const initialItem = {
      // code: appUtils.randomCode(10),
      title: '',
      order_number: 1
    }
    return {
      required,
      initialItem,
      detail: {
        id: '',
        title: '',
        title_en: ''
      },
      delivery_items: [],
      appUtils
    }
  },
  mounted() {
    this.setStateData(this.recordDetail)
    console.log('this.detail', this.detail)
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      this.resetForm()
    })
  },
  watch: {
    recordDetail: {
      handler(data) {
        this.setStateData(data)
      }
    }
  },
  methods: {
    setStateData(data) {
      this.detail = {
        id: data?.id || '',
        title: data?.title || '',
        title_en: data?.product_delivery_configs_multilanguage?.find(
          elm => elm.language == 'en'
        )?.title
      }
      // this.delivery_items = ORDER_DELIVERY_STATES?.length
      //   ? ORDER_DELIVERY_STATES?.map(elm => {
      //       return {
      //         ...elm,
      //         title: elm.title,
      //         title_en: elm.product_delivery_states_multilanguage?.find(
      //           item => item.language == 'en'
      //         )?.title
      //       }
      //     })
      //   : [{ ...this.initialItem }]
      this.delivery_items = data.product_delivery_states?.length
        ? data.product_delivery_states?.map(elm => {
            return {
              ...elm,
              title: elm.title,
              title_en: elm.product_delivery_states_multilanguage?.find(
                item => item.language == 'en'
              )?.title
            }
          })
        : [{ ...this.initialItem }]
    },
    resetForm() {
      this.detail = {
        id: '',
        title: '',
        title_en: ''
      }
      this.delivery_items = [{ ...this.initialItem }]
    },
    addNewDeliverable(data) {
      data.push({
        // code: appUtils.randomCode(10),
        title: '',
        order_number: data.length + 1
      })
    },
    removeItem(index, data, callback) {
      data.splice(index, 1)
      this.delivery_items = data?.map(item => {
        if (item.order_number > index + 1) {
          return {
            ...item,
            order_number: item.order_number - 1
          }
        } else return { ...item }
      })
      if (callback instanceof Function) {
        callback()
      }
    },
    handleDragChanged(e) {
      const newData = this.delivery_items?.map((item, index) => {
        return {
          ...item,
          order_number: index + 1
        }
      })
      this.delivery_items = [...newData]
      console.log(this.delivery_items)
    },
    handleOk(bvEvent) {
      bvEvent.preventDefault()
      this.handleSubmit()
    },
    addForm() {
      try {
        const userData = appUtils.getLocalUser()
        const data = {
          title: this.detail.title,
          supplier_id: userData?.supplierInfo?.id,
          delivery_items: this.delivery_items.map(elm => {
            if (elm.title_en) {
              return {
                title: elm.title,
                order_number: elm.order_number,
                max_steps: this.delivery_items.length,
                product_config_item_multilanguage_input: [
                  {
                    title: elm.title_en,
                    order_number: elm.order_number,
                    language: 'en',
                    max_steps: this.delivery_items.length
                  }
                ]
              }
            } else {
              return {
                title: elm.title,
                order_number: elm.order_number,
                max_steps: this.delivery_items.length
              }
            }
          })
        }
        if (this.detail.title_en) {
          data.product_delivery_configs_multilanguage = [
            {
              title: this.detail.title_en,
              language: 'en'
            }
          ]
        }
        this.$store
          .dispatch('categories/createDeliveryStatusConfigs', data)
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('lbl_add_new_successfully'),
                icon: 'CheckIcon',
                variant: 'success'
              }
            })
          })
      } catch (error) {
        console.log(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('lbl_an_error_occurred_while_adding_new'),
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
      }
    },
    updateForm() {
      try {
        let data = {
          id: this.detail?.id,
          title: this.detail.title,
          supplier_id: this.detail.supplier_id,
          delivery_items: this.delivery_items.map(elm => {
            if (elm.title_en) {
              return {
                title: elm.title,
                order_number: elm.order_number,
                max_steps: this.delivery_items.length,
                product_config_item_multilanguage_input: [
                  {
                    title: elm.title_en,
                    order_number: elm.order_number,
                    language: 'en',
                    max_steps: this.delivery_items.length
                  }
                ]
              }
            } else {
              return {
                title: elm.title,
                order_number: elm.order_number,
                max_steps: this.delivery_items.length
              }
            }
          })
        }
        if (this.detail.title_en) {
          data.product_delivery_configs_multilanguage = [
            {
              title: this.detail.title_en,
              language: 'en'
            }
          ]
        }
        this.$store
          .dispatch('categories/updateDeliveryStatusConfigsById', {
            id: this.detail?.id,
            data: data
          })
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('lbl_updated_successfully'),
                icon: 'CheckIcon',
                variant: 'success'
              }
            })
          })
      } catch (error) {
        console.log(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('lbl_an_error_occurred_while_updating'),
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
      }
    },
    handleSubmit() {
      this.$refs.deliveryStatusFormValidation.validate().then(async success => {
        if (success) {
          if (this.detail?.id) {
            this.updateForm()
          } else {
            this.addForm()
          }

          this.$emit('refresh')
          this.$nextTick(() => {
            this.$bvModal.hide('modal-detail-delivery-status')
          })
        }
      })
    }
  }
}
</script>

<style>
</style>
